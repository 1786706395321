import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TranslationService } from './modules/i18n';
import { OneSignal } from 'onesignal-ngx';
import { environment } from 'src/environments/environment';
import { UserService } from './service/User.Service';
// language list
import { locale as enLang } from './modules/i18n/vocabs/en';
import { locale as chLang } from './modules/i18n/vocabs/ch';
import { locale as esLang } from './modules/i18n/vocabs/es';
import { locale as jpLang } from './modules/i18n/vocabs/jp';
import { locale as deLang } from './modules/i18n/vocabs/de';
import { locale as frLang } from './modules/i18n/vocabs/fr';
import { ThemeModeService } from './_metronic/partials/layout/theme-mode-switcher/theme-mode.service';

@Component({
  // tslint:disable-next-line:component-selector
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  appname = "Template";
  constructor(
    private oneSignal: OneSignal,
    private translationService: TranslationService,
    private modeService: ThemeModeService,
    private userService: UserService
  ) {
    // register translations
    this.translationService.loadTranslations(
      enLang,
      chLang,
      esLang,
      jpLang,
      deLang,
      frLang
    );
  }


  ngOnInit(): void {
    this.modeService.init();
    this.oneSignal.init({
      appId: environment.ONE_SIGNAL_ID,
      autoRegister: true,
    });
    this.oneSignal.isPushNotificationsEnabled((enabled: any) => {
      this.oneSignal.getUserId().then((userId: any) => {
        this.userService.updateToken({ token: userId }, (result: any) => {
          console.log(userId);
        });
      })
    })
  }

}

